
export const UnitMixin = {
  name: 'Tab',
  data () {
    return {
      prognose: {},
      tarieven: [{ text: 'Geen', value: 0 }, { text: '19%', value: 1 }, { text: '21%', value: 2 }],
      keuringsstatussen: [
        { text: 'Goedgekeurd', value: 1 },
        { text: 'Afgekeurd', value: 0 },
        { text: 'Onbekend', value: -1 },
        { text: 'NVT', value: -2 }
      ]
    }
  },
  computed: {
    BTWTarief: function () {
      if (!this.unit) return '-'
      if (this.tarieven.map(t => t.value).includes(this.unit.Btw)) return this.tarieven.filter(f => f.value === this.unit.Btw)[0].text
      return 'nb'
    },
    Keuringsstatus: function () {
      if (!this.unit) return '-'
      if (!this.keuringsstatussen.map(t => t.value).includes(this.unit.KeuringsStatus)) return 'nb'
      return this.keuringsstatussen.filter(f => f.value === this.unit.KeuringsStatus)[0].text
    }
  },
  props: {
    unit: {
      type: Object,
      default: () => {
        return {
          UnitKenmerk: 'geen'
        }
      }
    },
    remoteid: {
      type: String
    }

  }

}
