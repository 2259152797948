<template>
<b-overlay :show="busy" >
  <b-container >
    <b-list-group>
      <b-card-title>Downloads <small>beta</small></b-card-title>
      <b-list-group-item v-for="e in exports" :key="e.path"
      class="d-flex justify-content-between align-items-center">
      {{e.title}}
      <b-button size="sm" @click="()=> startDownload(e)"  variant="primary"><font-awesome-icon icon="download" /> download .xlsx </b-button>
      </b-list-group-item>
    </b-list-group>
  </b-container>
  </b-overlay>
</template>
<script>
import { db, auth } from '../firebase'
import { mapGetters } from 'vuex'
import { saveAs } from 'file-saver'

export default {
  components: {

  },
  data () {
    return {
      profiel: null,
      exports: [],
      zoekterm: null,
      busy: false
    }
  },
  firestore: {
    exports: db.collection('exports')
  },
  computed: {
    ...mapGetters({
      user: 'user'
    }),
    userId: function () {
      const user = auth.currentUser
      return user.uid
    },
    authed: function () {
      return auth.currentUser
    }
  },
  mounted () {
    this.getProfiel()
  },
  methods: {
    /* getHuidigePrognose (unit) {
      db.doc('units/' + unit.RemoteId + '/prognoses/HuidigePrognose').get().then(r => {
        console.log(r.data())
        this.$set(unit, 'Prognose', r.data())
      }).catch(e => console.log(e))
    }, */
    getProfiel () {
      var vm = this
      console.log(this.userId)
      return db.collection('users').doc(this.userId).get().then(snapshot => {
        vm.profiel = snapshot.data()
      })
    },
    async startDownload (download) {
      this.busy = true
      console.log(download.id)
      const token = await auth.currentUser.getIdToken()
      console.log(token)

      // fetch('http://localhost:5001/tpa-dla/europe-west3/downloadExcel?export=' + download.id, {
      fetch('https://europe-west3-tpa-dla.cloudfunctions.net/downloadExcel?export=' + download.id, {
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'text/plain'
        }
      })
        .then(r => {
          console.log(r)
          r.arrayBuffer().then(data => {
            var blob = new Blob([data], {
              type:
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            })
            saveAs(blob, download.filename)
          }).finally(
            this.busy = false
          )
        }).catch(e => console.log(e))
    }
  }
}
</script>
