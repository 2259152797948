<template>
    <div v-b-hover="hoverHandler">
       <b-card-img v-if="isHovered" :src="unitImage" top></b-card-img>
       <b-card-img  v-else :src="defaultImage" top></b-card-img>
    </div>
</template>
<script>
export default {
  name: 'UnitCardImage',
  data () {
    return {
      isHovered: false
    }
  },
  props: {
    unitImage: {
      type: String,
      default: null
    },
    complexImage: {
      type: String,
      default: null
    }
  },
  computed: {
    defaultImage: function () {
      if (this.complexImage !== null && this.complexImage !== '/img/no-img.jpg') return this.complexImage
      if (this.unitImage !== null) return this.unitImage
      return 'https://res.cloudinary.com/transplan/image/upload/t_thumb/v1548422535/complex/480'
    }
  },
  methods: {
    hoverHandler (isHovered) {
      this.isHovered = isHovered
    }
  }
}
</script>
