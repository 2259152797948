<template>
    <b-container v-if="prognose.Uitkomst">
      <b-table
        :fields="columns"
        bordered
        :items="items.filter(r => [].concat(r.resultaat.length) > 0 || r.level === 0)"
        small
        foot-clone>

        <template v-slot:thead-top>
          <tr>
            <th
            >Overzicht</th>
            <th v-for="j in jaartallen" :key="j.key" align="center" class="table-dark">{{j.label}}</th>
          </tr>

          <tr v-if="unit.Instellingen.standaardCorrectief > 0"  class="cols">
            <th class="naam"  colspan="3">Correctief</th>
            <td v-for="j in jaartallen" :key="j.key"  class="number_cell">{{unit.Instellingen.standaardCorrectief}}</td>
          </tr>

          <tr v-if="unit.Instellingen.standaardPreventief > 0"  class="cols">
            <th class="naam"  colspan="3">Preventief</th>
            <td v-for="j in jaartallen" :key="j.key"  class="number_cell">{{unit.Instellingen.standaardCorrectief}}</td>
          </tr>

          <tr v-if="overig > 0"  class="cols">
            <th class="naam"  colspan="3">Overig</th>
            <td v-for="j in jaartallen" :key="j.key"  class="number_cell">{{overig}}</td>
          </tr>
        </template>

        <template v-slot:head()="data">
          <div v-if="isNaN(data.label)">{{ data.label }}</div>
          <div v-else class="number_cell"> {{(prognose.Uitkomst.prognose.totalen[data.label] || 0).toLocaleString('nl-NL')}} </div>
        </template>

        <template v-slot:cell(Titel)="data">
          {{data.item.Titel}} <small class="float-right">{{data.item.Tag}}</small>
        </template>

        <template v-slot:cell()="data">
          <div v-if="data.item.showJaren">{{data.field.label}}</div>
          <div v-else class="number_cell">
            {{ cellTotaal(data.item.resultaat,Number(data.field.label),data.item.multiplier).reduce((a,b) => a += b.bedrag, 0).toLocaleString('nl-NL')}}
          </div>
        </template>

      </b-table>
    </b-container>
</template>
<script>
import { UnitMixin } from '@/mixins/UnitMixin'
import { db } from '../firebase'

export default {
  name: 'TabPrognose',
  mixins: [UnitMixin],
  data () {
    return {
      config: {
        jaar: new Date().getFullYear(),
        jaren: 10
      }
    }
  },
  methods: {
    cellTotaal (resultaat, jaar, multiplier) {
      var group = resultaat.map(r => {
        if (r.jaren) {
          if (r.jaren.includes(jaar)) return { bedrag: (jaar === r.startjaar ? (!isNaN(r.actie.startbedrag) ? r.actie.startbedrag : 0) : (!isNaN(r.actie.bedrag) ? r.actie.bedrag : 0)) * multiplier }
        } else {
          if (Number(r.jaar) === jaar) return { bedrag: r.bedrag }
        }
        return { bedrag: 0 }
      })
      return group
    }
  },
  computed: {
    standaard: function () {
      return this.unit.Complex.Relatie.Config
    },
    overig: function () {
      return Number(this.unit.Instellingen.standaardSpreekluister) + Number(this.unit.Instellingen.standaardAdvieskosten) + Number(this.unit.Instellingen.standaardKeuringskosten)
    },
    groepen () {
      return this.prognose.Uitkomst.prognose.groepen
    },
    items () {
      if (!this.prognose.Uitkomst) return []
      var groepen = this.groepen
      return [].concat.apply([], this.prognose.Uitkomst.prognose.rijen.map(r => {
        if (r.level === 0) {
          return { Titel: r.titel, _rowVariant: 'secondary', showJaren: true, resultaat: r.resultaat, level: r.level, multiplier: r.multiplier }
        } else {
          return r.groepen.map(g => {
            return {
              Titel: r.titel,
              level: r.level,
              multiplier: r.multiplier,
              Tag: groepen[g.groep].tag,
              showJaren: false,
              resultaat: r.groepen[g.groep].resultaat
            }
          })
        }
      }))
    },
    columns () {
      var cols = [
        {
          key: 'Titel',
          stickyColumn: true
        }
      ]
      for (var j in this.jaartallen) {
        cols.push(this.jaartallen[j])
      }
      return cols
    },
    jaartallen () {
      var items = []
      while (items.length < this.config.jaren) {
        items.push({
          label: (
            Number(Math.round(this.config.jaar)) + items.length
          ).toString(),
          thStyle: {
            'min-width': '55px'
          },
          key: items.length.toString(),
          isRowHeader:
            this.config.jaar + items.length === new Date().getUTCFullYear(),
          variant:
            this.jaar + items.length === new Date().getUTCFullYear()
              ? 'info'
              : null
        })
      }
      return items
    }
  },
  watch: {
    'unit.RemoteId': {
      // call it upon creation too
      immediate: true,
      handler (id) {
        this.$bind('prognose', db.collection('units').doc(id).collection('prognoses').doc('HuidigePrognose'))
      }
    }
  }
}
</script>

<style lang="scss" scoped>

    .prognose-tabel  {
        caption-side:bottom;
        border-collapse: collapse;
    }
    caption {
        font-size:9px;
        padding:3px;
    }
    .number_cell {
      text-align:right;
    }
    .prognose-tabel th {
        font-weight:bold;
    }
    .prognose-tabel .rt {
        text-align: right;
    }
    .prognose-tabel .subheader td {
        background-color: #002163;
        font-weight:bold;
        color:white;
    }
    .prognose-tabel .error {
        background-color: LightPink;
    }

    .prognose-tabel .even {
        background-color: #ddd;
    }

    .prognose-tabel .odd {
        background-color: #fff;
    }

    .prognose-tabel .even-historie {
        background-color: Lavender  ;
    }
    .prognose-tabel .odd-historie {
        background-color: AliceBlue ;
    }
    .prognose-tabel .cols td,th {
        border-bottom:1px solid #002163;
    }
    .prognose-tabel .cols .naam {
        border-right:1px solid #002163;
    }

    .conditie-1 {
        background-color: green;
    }
    .conditie-2 {
        background-color: greenyellow;
    }
    .conditie-3 {
        background-color: yellow;
    }
    .conditie-4 {
        background-color: orange;
    }
    .conditie-5 {
        background-color: darkorange;
    }
    .conditie-6 {
        background-color: red;
    }

</style>
