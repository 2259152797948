<template>
  <div class="text-right">
    <!---<b-button
      @click="getRapport"
      size="sm"
      :disabled="remoteid == null"
      variant="outline-danger"
      ><font-awesome-icon icon="file-pdf" /> Genereer rapport</b-button
    >--->
    <b-button
      @click="getPdf"
      :disabled="remoteid == null || this.busy"
      variant="outline-primary"
      ><font-awesome-icon icon="file-download" />
       Download PDF
       <font-awesome-icon icon="spinner" v-if="busy" spin></font-awesome-icon>
       </b-button
    >
  </div>
</template>
<script>
import { saveAs } from 'file-saver'

export default {
  name: 'DownloadButton',
  components: {},
  mixins: [],
  props: {
    remoteid: {
      type: String,
      default: null
    },
    filename: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      busy: false
    }
  },
  computed: {},
  watch: {},
  mounted () {},
  methods: {
    async getPdf () {
      this.busy = true
      // fetch('http://localhost:5001/tpa-dla/europe-west3/downloadPDF?remoteid=' + this.remoteid).then(r => {
      fetch('https://europe-west3-tpa-dla.cloudfunctions.net/downloadPDF?remoteid=' + this.remoteid).then(r => {
        console.log(r)
        r.arrayBuffer().then(data => {
          var blob = new Blob([data], {
            type:
              'application/pdf;charset=utf-8'
          })
          saveAs(blob, this.filename)
        }).finally(
          this.busy = false
        )
      }).catch(e => console.log(e))
    }
  }
}
</script>
