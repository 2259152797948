<template>
    <b-container>
        <b-input v-model="search"/>
        <br/>
        etag:
        <div v-for="(item, index) in etag" :key="index" style="border: 1px solid black;">
            {{ item }}
        </div>
        asset_id:{{ asset_id.length }}<br/>

    </b-container>
</template>
<script>
import { db } from '../firebase'

export default {
  name: 'DocumentenSearch',
  components: {},
  mixins: [],
  props: {
  },
  data () {
    return {
      search: '',
      etag: [],
      asset_id: []
    }
  },
  computed: {},
  watch: {
    search: {
      immediate: false,
      // call it upon creation too
      handler (value) {
        console.log(value)
        this.$bind(
          'etag',
          db.collectionGroup('documenten').where('metadata.etag', '==', value)
            .get()
            .then(snapshot => {
              this.etag = snapshot.docs.map(f => { return { id: f.id, path: f.ref.path } })
              // do something with document
            })
        )
        this.$bind(
          'asset_id',
          db.collectionGroup('documenten').where('metadata.asset_id', '==', value)
        )
      }
    }
  },
  mounted () {

  },
  methods: {}

}
</script>
