import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Units from '../views/Units.vue'
import Unit from '../views/Unit.vue'
import Planning from '../views/Planning.vue'
import Actueel from '../views/Actueel.vue'
import Documenten from '../views/Documenten.vue'
import Admin from '../views/Admin'
import UnitAlgemeen from '../views/UnitAlgemeen.vue'
import UnitInspectie from '../views/UnitInspectie.vue'
import UnitInventarisatie from '../views/UnitInventarisatie.vue'
import UnitPrognose from '../views/UnitPrognose'
import UnitFotos from '../views/UnitFotos'
import UnitDocumenten from '../views/UnitDocumenten'
import ServiceOverzicht from '../views/Service'
import Downloads from '../views/Downloads'
import Profile from '../views/Profile.vue'
import Login from '../views/Login.vue'
import store from '../store/index'
import firebase from 'firebase/app'
import 'firebase/auth'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: '/units',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/units',
    name: 'Units',
    component: Units,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/planning',
    name: 'Planning',
    component: Planning,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/documenten',
    name: 'Documenten',
    component: Documenten,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/actueel',
    name: 'Actueel',
    component: Actueel,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/downloads',
    name: 'Downloads',
    component: Downloads,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/unit/:remoteid',
    name: 'Unit',
    props: true,
    component: Unit,
    redirect: '/unit/:remoteid/algemeen',
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'algemeen',
        name: 'UnitAlgemeen',
        component: UnitAlgemeen,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'inventarisatie',
        name: 'UnitInventarisatie',
        component: UnitInventarisatie,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'inspectie',
        name: 'UnitInspectie',
        component: UnitInspectie,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'prognose',
        name: 'UnitPrognose',
        component: UnitPrognose,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'fotos',
        name: 'UnitFotos',
        component: UnitFotos,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'documenten',
        name: 'UnitDocumenten',
        props: true,
        component: UnitDocumenten,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'service',
        name: 'UnitService',
        props: true,
        component: ServiceOverzicht,
        meta: {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/profiel',
    name: 'Profile',
    component: Profile,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    meta: {
      requiresAuth: true
    }
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  firebase.auth().onAuthStateChanged((user) => {
    if (to.matched.some((record) => record.meta.requiresAuth) && !store.getters.user.loggedIn) {
      next('/login')
    } else {
      next()
    }
  })
})

export default router
