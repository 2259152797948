<template>
  <b-container>
    <b-navbar>
      <b-navbar-brand>
        <img alt="Transplan" class="my-4"  src="@/assets/donken-liftadvies-logo.png" width="220" height="81" style="object-fit:contain;" />
      </b-navbar-brand>

      <b-navbar-nav>
        <b-nav-item :to="{name:'Units'}" :active="matched === 'Units'">Units</b-nav-item>
        <b-nav-item :to="{name:'Downloads'}" :active="matched === 'Downloads'">Downloads</b-nav-item>
        <!-- <b-nav-item :to="{name:'Documenten'}" :active="matched === 'Documenten'">Documenten</b-nav-item> -->
        <!---<b-nav-item :to="{name:'Planning'}" :active="$route.matched[0].name === 'Planning'">Planning <b-badge>beta</b-badge></b-nav-item>--->
      </b-navbar-nav>

        <b-navbar-nav class="ml-auto">
          <template v-if="user.loggedIn" >
            <b-nav-item right :to="{name:'Profile'}">{{user.data.email}}</b-nav-item>
            <b-nav-item @click.prevent="signOut" right>Afmelden</b-nav-item>
          </template>
          <template v-else>
            <b-nav-item :to="{name:'Login'}" right>Inloggen</b-nav-item>
          </template>
        </b-navbar-nav>
    </b-navbar>

    <router-view/>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex'
import firebase from 'firebase'
export default {
  computed: {
    menu () {
      return [{ text: 'Portal', to: { name: 'Home' } }].concat(
        this.$route.matched.map(function (item) {
          return { text: item.name, to: { name: item.name } }
        })
      )
    },
    matched () {
      if (this.$route.matched.length > 0) {
        return this.$route.matched[0].name
      } else {
        return null
      }
    },
    ...mapGetters({
      user: 'user'
    })
  },
  watch: {
    user (newValue, oldValue) {
      console.log('test')
    }
  },
  methods: {
    signOut () {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.replace({
            name: 'Login'
          })
        })
    }
  }
}
</script>
