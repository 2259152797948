<template>
    <b-container>
     <!--- /units/{{unit.RemoteId}}/Fotos/ --->

      <b-card-group columns>
      <b-card v-for="(n,idx) in unit.Fotos"  :key="idx" no-body>
        <b-card-header>
         {{n.titel}}
        </b-card-header>
         <b-card-img :src="n.secure_url" bottom></b-card-img>
      </b-card>
      </b-card-group>

    </b-container>
</template>
<script>
import { UnitMixin } from '@/mixins/UnitMixin'

export default {
  name: 'TabFotos',
  mixins: [UnitMixin],
  data () {
    return {
    }
  },
  methods: {
  },
  computed: {

  }
}
</script>
