<template>
     <b-card title="Actueel">
        <b-alert :show="service.length == 0">Geen lopende activiteiten!</b-alert>
        <b-table :items="service" />
     </b-card>
</template>
<script>
import { db, auth } from '../firebase'
import { mapGetters } from 'vuex'
export default {
  name: 'Actueel',
  components: {},
  mixins: [],
  props: {
  },
  data () {
    return {
      service: [],
      profiel: null
    }
  },
  computed: {
    ...mapGetters({
      user: 'user'
    }),
    userId: function () {
      const user = auth.currentUser
      return user.uid
    }
  },
  watch: {
    'profiel.adviesnummer': {
      handler (adviesnummer) {
        this.$bind('service', db.collectionGroup('service').where('advies_id', '==', adviesnummer).where('stopped', '==', null).orderBy('reported', 'desc'))
      }
    }
  },
  mounted () {
    this.getProfiel()
  },
  methods: {
    getProfiel () {
      var vm = this
      console.log(this.userId)
      return db
        .collection('users')
        .doc(this.userId)
        .get()
        .then((snapshot) => {
          vm.profiel = snapshot.data()
        })
    }
  }

}
</script>
