<template>
    <b-container>
    <div v-if="elementen && unit" class="my-3">
      <b-card-group columns>
      <b-card v-for="n in unit.Elementen.filter(e => groupItems(elementen[e]).length > 0)"  :key="n" no-body>
        <b-card-header>
          <b-badge class="float-right">{{n}}</b-badge>
          {{elementen[n] ? elementen[n].Naam : 'geen'}}
        </b-card-header>
          <b-list-group flush>
            <b-list-group-item v-for="(k,ind) in groupItems(elementen[n])" class="d-flex justify-content-between align-items-center" :key="ind" ><div>{{k['#text']}}</div> <small>{{inventarisatie[k['-id'].toUpperCase()]}}</small></b-list-group-item>
          </b-list-group>
      </b-card>
      </b-card-group>
    </div>
  </b-container>
</template>
<script>
import { UnitMixin } from '@/mixins/UnitMixin'
import { db } from '../firebase'

export default {
  name: 'TabInventarisatie',
  mixins: [UnitMixin],
  data () {
    return {
      elementen: {}
    }
  },
  methods: {
    hasEigenschap (code) {
      return this.inventarisatie[code.toUpperCase()] != null
    },
    groupItems (group) {
      if (!group.Form.group) return []
      return [].concat.apply([], group.Form.group.map(g => (g.item).map(i => i)))
    }
  },
  computed: {
    inventarisatie: function () {
      return this.unit.Inventarisatie
    }
  },
  firestore: {
    elementen: db.collection('elementen').doc('basis')
  }
}
</script>
