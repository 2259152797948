<template>

  <b-table striped small bordered :fields="['message','reported','started','stopped','runtime_type','note']" :items="ticketsSorted">
    <template v-slot:cell(reported)="row">{{ $moment(row.item.reported).format('LLL')}} </template>
    <template v-slot:cell(started)="row">{{ row.item.started ? $moment(row.item.started).format('LLL') : '-'}} </template>
    <template v-slot:cell(stopped)="row">{{ row.item.stopped ? $moment(row.item.stopped).format('LLL') : '-'}}  </template>
  </b-table>

</template>

<script>
import { UnitMixin } from '@/mixins/UnitMixin'
import { db } from '../firebase'
export default {
  name: 'Service',
  components: {},
  mixins: [UnitMixin],
  props: {
  },
  data () {
    return {
      tickets: []
    }
  },
  computed: {
    servicepath: function () {
      return `/units/${this.unit.RemoteId}/service`
    },
    ticketsSorted: function () {
      return this.tickets.slice(0).sort((a, b) => a.reported < b.reported)
    }
  },
  watch: {
    'unit.RemoteId': {
      // call it upon creation too
      immediate: true,
      handler (id) {
        this.$bind('tickets', db.collection(this.servicepath))
      }
    }
  },
  mounted () {

  },
  methods: {}

}
</script>
