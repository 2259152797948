<template>
  <b-container>
    <b-row class="justify-content-center">
        <b-col md="4">
            <b-card no-body >
              <b-card-body class="p-4">
                <b-form @submit.prevent="onSubmit">

                  <h1>Inloggen</h1>

                  <b-card bg-variant="success" v-if="result == 200">
                    <span v-html="result"></span> Aanmelden gelukt!
                  </b-card>

                  <b-card bg-variant="danger" v-if="result > 200">
                    <span v-html="result"></span> Aanmelden mislukt!
                  </b-card>

                  <p class="text-muted">Aanmelden met de accountgegevens.</p>

                  <b-input-group class="mb-3">
                    <b-input-group-prepend>
                      <b-input-group-text>
                        <font-awesome-icon icon="user"/>
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input
                      type="text"
                      autofocus
                      class="form-control"
                      v-model="uid"
                      placeholder="Gebruikersnaam"
                      autocomplete="username email"
                    />
                  </b-input-group>
                  <b-input-group class="mb-4">
                    <b-input-group-prepend>
                      <b-input-group-text>
                        <font-awesome-icon icon="lock"/>
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input
                      type="password"
                      class="form-control"
                      v-model="pwd"
                      v-on:keyup.enter="onSubmit"
                      placeholder="Wachtwoord"
                      autocomplete="current-password"
                    />
                  </b-input-group>
                  <b-row>
                    <b-col cols="6">
                      <b-button
                        variant="primary"
                        type="sumbit"
                        class="px-4"
                        @click="onSubmit()"
                      >Aanmelden</b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </b-card-body>
              <b-card-footer >
                  <b-alert :show="result != null">{{result}}</b-alert>
              </b-card-footer>
            </b-card>
        </b-col>
      </b-row>
  </b-container>
</template>
<script>
import { auth } from '../firebase'

export default {
  name: 'Login',
  data () {
    return {
      uid: null,
      pwd: null,
      result: null
    }
  },
  methods: {
    onSubmit () {
      var vm = this
      auth.signInWithEmailAndPassword(this.uid, this.pwd)
        .then((userCredential) => {
          // Signed in
          console.log('Signed in')
          this.$router.push('/')
        })
        .catch((error) => {
          vm.result = error
          console.log(error)
        })
    }
  }
}
</script>
