<template>
    <b-container v-if="unit">
       <b-card-group columns>
        <b-card no-body>
          <b-carousel controls   style="border-radius: 3px 3px 0px 0px;
    overflow: hidden;" >
            <b-carousel-slide :img-src="unit.Complex.Thumbnail" key="ComplexSlide"></b-carousel-slide>

            <b-carousel-slide :img-src="unit.Thumbnail" key="UnitSlide"></b-carousel-slide>
          </b-carousel>
          <b-card-footer  class="text-right">
            <b-link :to="{name: 'UnitFotos'}">Bekijk meer foto's</b-link>
          </b-card-footer>
          </b-card>

        <b-card no-body >
          <b-card-header>Unit</b-card-header>
          <b-list-group flush>
            <item label="Titel van de unit">{{unit.Titel}}</item>
            <item label="Kenmerk">{{unit.KenmerkObject}}</item>
            <item label="Locatie van unit">{{unit.Locatievanobject}}</item>
            <item label="Adres">{{unit.Adres}}<br>{{unit.Postcode}} {{unit.WijkPlaats}}</item>
            <item label="Regio">{{unit.Regio}}</item>
          </b-list-group>
        </b-card>

        <b-card no-body>
          <b-card-header>
            Type {{unit.Type}} unit
          </b-card-header>
             <b-list-group flush>
            <item label="Leverancier">{{unit.Leverancier}}</item>
            <item label="Fabrieksnr/Installatienr">{{unit.Fabrieksnummer}}</item>
            <item label="Bouwjaar offset">{{unit.OffsetJaren}}</item>
            <item label="Bouwjaar">{{unit.Bouwjaar}}</item>
            <item label="Ingebruikname">{{$moment(unit.Ingebruikname).format('LL')}}</item>
            <item label="Modernisering/Revisie">{{unit.ModRevisieJaar}}</item>
            <item label="Algemene conditie">{{unit.AlgemeneConditie}}</item>
             </b-list-group>
        </b-card>
        <b-card no-body>
          <b-card-header>Overig</b-card-header>
           <b-list-group flush>
            <item label="Eco Technisch">{{unit.EcoTechnisch}}</item>
            <item label="Eco Omgeving">{{unit.EcoOmgeving}}</item>
            <item label="Norm">{{unit.Norm}}</item>
            <item label="BTW">{{BTWTarief}}</item>
           </b-list-group>
        </b-card>

        <b-card no-body>
          <b-card-header>Onderhoud</b-card-header>
            <b-list-group flush>
              <!---<item label="Onderhoudsfirma"></item>--->
              <b-list-group-item >
                <div>Onderhoudsfirma</div>
                <p class="small my-1">
                  {{unit.Onderhoudsfirma ? unit.Onderhoudsfirma.Naam : '-Geen-'}}
                </p>
              </b-list-group-item>
              <item label="Soort onderhoud">{{unit.Instellingen.soortOnderhoud}}</item>
              <item label="Onderhoudsnummer">{{unit.Onderhoudsnummer}}</item>
              <item label="Onderhoudscategorie">{{unit.Onderhoudscategorie}}</item>
              <item label="Vereiste reactietijd">{{unit.VereisteReactietijd}}</item>
               <item label="Is conditie na">{{unit.Instellingen.isConditieNa ? 'ja' : 'nee'}}</item>
            </b-list-group>
        </b-card>

        <b-card no-body>
          <b-card-header>Keuringen</b-card-header>
          <b-list-group flush>
            <item label="Keuringsinstantie">{{unit.Keuringsinstantie}}</item>
            <item label="Keuringsnummer">{{unit.Keuringsnummer}}</item>
            <item label="Keuringsdatum">{{$moment(unit.Keuringsdatum).format('LL')}}</item>
            <item label="Keuringsstatus">{{keuringsstatussen.filter(s => s.value === unit.KeuringsStatus)[0].text}}</item>
            <item label="Keuringsalaram">{{unit.Keuringsalarm == 1 ? 'ja' : 'nee'}}</item>
            <b-list-group-item >
              <div>Keuringsopmerking</div>
              <p class="small my-1">
                {{unit.Keuringsopmerking}}
              </p>
            </b-list-group-item>
          </b-list-group>
        </b-card>

        <b-card no-body>
          <b-card-header>
            Complex {{unit.Complex.ComplexKenmerk}}
          </b-card-header>
          <b-list-group flush>
            <item label="Huismeester">{{unit.Complex.Huismeester}} <i class="fa fa-phone"></i> {{unit.Complex.Telefoon}}</item>
            <item label="Relatie">{{unit.Complex.Relatie.Naam}}</item>
            <b-list-group-item>
              Units binnen dit complex
            <b-list-group>
              <b-list-group-item v-for="u in unit.Complex.Units" :to="{name: 'Unit',params: {remoteid: u.RemoteId}}" :key="u.Id" :variant="u.RemoteId == unit.RemoteId ? 'primary' : 'secondary'">
                {{u.Kenmerk}} {{u.Adres}}
              </b-list-group-item>
            </b-list-group>
            </b-list-group-item>
          </b-list-group>
        </b-card>
       </b-card-group>
    </b-container>
</template>
<script>
import { UnitMixin } from '@/mixins/UnitMixin'
import Item from '@/components/Item'

export default {
  components: {
    Item
  },
  name: 'TabAlgemeen',
  mixins: [UnitMixin],
  data () {
    return {
      labelColsSm: 4,
      relaties: [],
      ecotags: [
        { text: 'Geen', value: 'X' },
        { text: 'A', value: 'A' },
        { text: 'B', value: 'B' },
        { text: 'C', value: 'C' },
        { text: 'D', value: 'D' },
        { text: 'E', value: 'E' }
      ]
    }
  },
  methods: {
  },
  computed: {

  }
}
</script>
