<template>
  <b-row v-if="units">
    <b-col>

      <google-map
        :center="this.center"
        :zoom="this.zoom"
        style="height: 500px; width: 100%"
      >
        <google-map-marker
          :key="index"
          v-for="(m, index) in unitsMetPositie"
          :position="m.Position"
          :clickable="true"
          :draggable="false"
          :content="m.Adres"
          @click="toggleInfoWindow(m)"
        >
        </google-map-marker>
      </google-map>
    </b-col>
    <b-col cols="3" v-if="showInfo">
      <b-card
        no-body
        :key="u.RemoteId"
       >
        <router-link :to="{ name: 'Unit', params: { remoteid: u.RemoteId } }">
          <unit-card-image
            :unitImage="u.Thumbnail"
            :complexImage="u.Complex.Thumbnail"
          ></unit-card-image>
          <b-card-body>
            {{ u.UnitKenmerk }}<br />{{ u.Adres }} {{ u.WijkPlaats }}
          </b-card-body>
        </router-link>
        <b-card-footer>
          <b-button
            variant="ghost"
            size="sm"
            class="float-right"
            :to="{ name: 'Unit', params: { remoteid: u.RemoteId } }"
            >Ga naar unit
            <font-awesome-icon icon="arrow-right"></font-awesome-icon
          ></b-button>
        </b-card-footer>
      </b-card>
      {{ infoWindowContext }}
    </b-col>
  </b-row>
</template>

<script>
import Vue from 'vue'
import VueGoogleMap from 'vuejs-google-maps'
import 'vuejs-google-maps/dist/vuejs-google-maps.css'
import UnitCardImage from '../components/UnitCardImage.vue'

Vue.use(VueGoogleMap, {
  load: {
    apiKey: 'AIzaSyACFqeIBU81ElQmpgI8_G7-HxuxMwl1w9U',
    libraries: ['places']
  }
})

export default {
  components: {
    UnitCardImage

  },
  name: 'Map',
  data () {
    return {
      showInfo: false,
      u: {
        Adres: null,
        Position: {
          lat: 44.2899,
          lng: 11.8774
        }
      }
    }
  },
  methods: {
    toggleInfoWindow (context) {
      this.center = context.Position
      this.zoom = 14
      this.u = { ...context }
      // this.$set(this.infoWindowContext, 'Adres', context.Adres)
      // this.$set(this.infoWindowContext, 'Position', context.Position)
      this.showInfo = true
    },
    infoClicked (context) {
      console.log(context)
    }
  },
  computed: {
    unitsMetPositie: function () {
      return this.units.filter((u) => u.Position.lat > 0 && u.Position.lng > 0)
    }
  },
  props: {
    units: {
      type: Array,
      default: () => []
    },
    center: {
      type: Object,
      default: () => {
        return { lat: 52.28143614800896, lng: 5.544413896972733 }
      }
    },
    zoom: {
      type: Number,
      default: 13
    }
  }
}
</script>
