<template>
    <b-container>
      <b-table small bordered :items="documenten" :fields="['type','omschrijving','afzender','datum','verzenddatum','kenmerk','download']">
        <template v-slot:cell(download)="row">
          <b-link :href="row.item.metadata.secure_url" target="_blank"><font-awesome-icon icon="download"></font-awesome-icon> downloaden</b-link>
        </template>
        <template v-slot:cell(datum)="row">
          {{$moment(row.item.datum).format('LL')}}
        </template>
        <template v-slot:cell(verzenddatum)="row">

          {{$moment(row.item.verzenddatum).format('LL')}}
         </template>
      </b-table>
    </b-container>
</template>
<script>
import { UnitMixin } from '@/mixins/UnitMixin'
import { db } from '../firebase'

export default {
  name: 'TabDocumenten',
  mixins: [UnitMixin],
  data () {
    return {
      documenten: []
    }
  },
  watch: {
    'unit.RemoteId': {
      // call it upon creation too
      immediate: true,
      handler (id) {
        this.$bind('documenten', db.collection('units').doc(id).collection('documenten'))
      }
    }
  },
  computed: {

  }
}
</script>
