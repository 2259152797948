<template>
    <b-card title="Documenten" v-if="profiel">
        <b-table small bordered :items="documenten" :fields="['type','omschrijving','afzender','datum','verzenddatum','kenmerk','download']">
        <template v-slot:cell(download)="row">
          <b-link :href="row.item.metadata.secure_url" target="_blank"><font-awesome-icon icon="download"></font-awesome-icon> downloaden</b-link>
        </template>
        <template v-slot:cell(datum)="row">
          {{$moment(row.item.datum).format('LL')}}
        </template>
        <template v-slot:cell(verzenddatum)="row">
          {{$moment(row.item.verzenddatum).format('LL')}}
         </template>
      </b-table>
  </b-card>
</template>
<script>
import { db, auth } from '../firebase'
import { mapGetters } from 'vuex'

export default {
  name: 'Documenten',
  data () {
    return {
      documenten: [],
      profiel: null
    }
  },
  watch: {
    'profiel.adviesnummer': {
      // call it upon creation too
      immediate: false,
      handler (id) {
        this.$bind('documenten', db.collection('relaties').doc(id).collection('documenten'))
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'user'
    }),
    userId: function () {
      const user = auth.currentUser
      return user.uid
    }
  },
  mounted () {
    this.getProfiel()
  },
  methods: {
    getProfiel () {
      var vm = this
      console.log(this.userId)
      return db
        .collection('users')
        .doc(this.userId)
        .get()
        .then((snapshot) => {
          vm.profiel = snapshot.data()
        })
    }
  }
}
</script>
