
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import store from './store'

// Get a Firestore instance
export const app = firebase
  .initializeApp({
    apiKey: 'AIzaSyDXo3qD7M5r1nDaxRBa4iwXsojtftGp4c4',
    authDomain: 'tpa-dla.firebaseapp.com',
    projectId: 'tpa-dla',
    storageBucket: 'tpa-dla.appspot.com',
    messagingSenderId: '165193825042',
    appId: '1:165193825042:web:c914c84e3c44078dc2d1cb'
  })

app.auth().onAuthStateChanged(user => {
  store.dispatch('fetchUser', user)
})
export const functions = app.functions('europe-west3')
// functions.useEmulator('localhost', 5001)

export const db = app.firestore()
export const auth = app.auth()
export const storage = app.storage()
