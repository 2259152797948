<template>
    <b-container>
    <b-table small bordered
    :items="rijen.filter(r => r.level != 1)"
    :fields="fields" head-row-variant="info" :tbody-tr-class="rowClass">
      <template v-slot:cell(conditie)="row">
        <div :class="`conditie conditie-${row.item.conditie}`">
          {{row.item.conditie}}
        </div>
      </template>
      <template v-slot:cell(conditieNa)="row">
        <div :class="`conditie conditie-${row.item.conditieNa}`">
          {{row.item.conditieNa}}
        </div>
      </template>
       <template v-slot:cell(belang)="row">
          {{row.item.IO ? belangen[row.item.IO.belang-1] : ''}}
      </template>
      <template v-slot:cell(intensiteit)="row">
          {{row.item.IO ? intensiteiten[row.item.IO.intensiteit-1] : ''}}
      </template>
      <template v-slot:cell(omvang)="row">
          {{row.item.IO ? omvangen[row.item.IO.omvang-1] : ''}}
      </template>
    </b-table>

    </b-container>
</template>
<script>
import { UnitMixin } from '@/mixins/UnitMixin'
import { db } from '../firebase'

export default {
  name: 'TabInspectie',
  mixins: [UnitMixin],
  methods: {
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.level === 0) return 'table-secondary'
    },
    getNENOpmerking (conditie) {
      if (conditie === 1) return 'Verval < 50% van de theoretische levensduur'
      if (conditie === 2) return 'Verval > 50% maar < 75% van de theoretische levensduur'
      if (conditie === 3) return 'Verval > 75% maar < 87,5% van de theoretische levensduur'
      if (conditie === 4) return 'Verval < 50% van de theoretische levensduur'
      return 'Verval > 87,5% van de theoretische levensduur'
    }
  },
  data () {
    return {
      standaardIOScores: {
        1: { belang: 1, intensiteit: 1, omvang: 3 },
        2: { belang: 1, intensiteit: 1, omvang: 5 },
        3: { belang: 1, intensiteit: 2, omvang: 5 },
        4: { belang: 2, intensiteit: 2, omvang: 5 },
        5: { belang: 2, intensiteit: 3, omvang: 5 },
        6: { belang: 3, intensiteit: 3, omvang: 5 }
      },
      belangen: ['Gering', 'Serieus', 'Ernstig'],
      intensiteiten: ['Laag', 'Gemiddeld', 'Hoog'],
      omvangen: ['<2%', '2% tot 10%', '10% tot 30%', '30% tot 70%', '70%>']
    }
  },
  computed: {
    toonNenOpmerking: function () {
      return this.unit.Complex.Relatie.Config.toonNENOpmerking
    },
    toonIOScore: function () {
      return this.unit.Complex.Relatie.Config.toonIOScore
    },
    standaard: function () {
      return this.unit.Complex.Relatie.Config
    },
    rijen: function () {
      if (!this.prognose.Uitkomst) return []
      var rows = this.prognose.Uitkomst.prognose.rijen
      var groepen = this.prognose.Uitkomst.prognose.groepen
      var NEN = this.toonNENOpmerking
      var vm = this
      var standaarden = {
        ConditieVervanging: this.standaard.standaardConditieVervanging,
        ConditieRevisie: this.standaard.standaardConditieRevisie
      }
      return [].concat.apply([], rows.map(r => [r].concat(r.acties.map(a => {
        return {
          titel: (a.actie.naam ? a.actie.naam.substr(0, 1) : 'nb') + ' - ' + r.titel,
          planjaar: a.planjaar,
          conditieNa: standaarden[groepen[a.groep].standaardConditie],
          conditie: a.conditie.conditie,
          IO: vm.standaardIOScores[a.conditie.conditie],
          opmerking: NEN ? vm.getNENOpmerking(a.conditie.conditie) : a.actie.omschrijving
        }
      })).concat(r.gebreken.map(g => {
        return {
          titel: 'G - ' + r.titel,
          planjaar: g.gebrek.jaar,
          conditieNa: g.gebrek.conditieNa,
          conditie: g.conditie,
          IO: g.gebrek,
          opmerking: NEN ? g.gebrek.NENomschrijving : g.gebrek.omschrijving
        }
      }))))
    },
    fields: function () {
      return [
        { key: 'titel', label: 'Actie - Onderdeel' },
        'belang', 'intensiteit', 'omvang', 'conditie',
        'opmerking', 'planjaar', 'conditieNa'
      ]
    }
  },
  watch: {
    'unit.RemoteId': {
      // call it upon creation too
      immediate: true,
      handler (id) {
        this.$bind('prognose', db.collection('units').doc(id).collection('prognoses').doc('HuidigePrognose'))
      }
    }
  }
}
</script>
<style scoped>
    .prognose-tabel  {
        caption-side:bottom;
        border-collapse: collapse;
    }
    caption {
        font-size:9px;
        padding:3px;
    }
    .prognose-tabel th {
        font-weight:bold;
    }
    .prognose-tabel .rt {
        text-align:right;
    }
    .prognose-tabel .subheader td {
        background-color: #002163;
        font-weight:bold;
        color:white;
    }
    .prognose-tabel .error {
        background-color: LightPink;
    }

    .prognose-tabel .even {
        background-color: #ddd;
    }

    .prognose-tabel .odd {
        background-color: #fff;
    }

    .prognose-tabel .even-historie {
        background-color: Lavender  ;
    }
    .prognose-tabel .odd-historie {
        background-color: AliceBlue ;
    }
    .prognose-tabel .cols td,th {
        border-bottom:1px solid #002163;
    }
    .prognose-tabel .cols .naam {
        border-right:1px solid #002163;
    }
    .conditie {
      text-align: center;
      color: black;
      font-weight: bold;
    }
    .conditie-1 {
        background-color: green;
    }
    .conditie-2 {
      background-color: greenyellow;
    }
    .conditie-3 {
        background-color: yellow;
    }
    .conditie-4 {
        background-color: orange;
    }
    .conditie-5 {
        background-color: darkorange;
    }
    .conditie-6 {
        background-color: red;
    }

</style>
