<template>
  <b-overlay :show="busy">
    <b-container>
      <b-button variant="icon" class="mb-2" :to="{name: 'Units'}" size="md"><font-awesome-icon icon="arrow-left"></font-awesome-icon> Terug naar units</b-button>
      <b-card no-body>
        <b-card-header v-if="unit">
            <div class="float-right">
              <download-button :remoteid="unit.RemoteId" :filename="`Rapport_${unit.UnitKenmerk}.pdf`"></download-button>
            </div>
            <b-card-title>{{unit.Titel}}</b-card-title>
            <b-card-sub-title>
              {{unit.Adres}} {{unit.WijkPlaats}}<br/>
              <small>{{unit.UnitKenmerk}}</small>
            </b-card-sub-title>

        </b-card-header>

       <b-nav tabs class="my-4 px-4">
        <b-nav-item :active="$route.name == 'UnitAlgemeen'" :to="{name: 'UnitAlgemeen'}">Algemeen</b-nav-item>
        <b-nav-item :active="$route.name == 'UnitInventarisatie'" :to="{name: 'UnitInventarisatie'}">Inventarisatie</b-nav-item>
        <b-nav-item :active="$route.name == 'UnitInspectie'" :to="{name: 'UnitInspectie'}">Inspectie</b-nav-item>
        <b-nav-item :active="$route.name == 'UnitPrognose'" :to="{name: 'UnitPrognose'}">Prognose</b-nav-item>
        <b-nav-item :active="$route.name == 'UnitFotos'" :to="{name: 'UnitFotos'}">Foto's</b-nav-item>
        <b-nav-item :active="$route.name == 'UnitService'" :to="{name: 'UnitService'}">Service <b-badge variant="primary">beta</b-badge></b-nav-item>
        <b-nav-item :active="$route.name == 'UnitDocumenten'" :to="{name: 'UnitDocumenten'}">Documenten</b-nav-item>
      </b-nav>
      <b-card-body>
        <router-view :unit="unit" v-if="unit"></router-view>
      </b-card-body>

      </b-card>
    </b-container>
    </b-overlay>
</template>
<script>
import DownloadButton from '../components/DownloadButton.vue'
import { db } from '../firebase'

export default {
  components: { DownloadButton },
  name: 'Unit',
  data () {
    return {
      unit: null,
      busy: false
    }
  },
  watch: {
    remoteid: {
      immediate: true,
      handler (id) {
        var vm = this
        this.busy = true
        db.collection('units').doc(id).get().then(response => { vm.unit = response.data() }).finally(() => { vm.busy = false })
      }
    }
  },
  props: {
    remoteid: {
      type: String
    }
  }
}
</script>
