<template>
    <b-container>
       <b-card>
         <b-form-group label="Naam">
           {{profiel.display}}
         </b-form-group>
         <b-form-group label="Adviesnummer" v-if="!profiel.admin">
           {{profiel.adviesnummer}}
         </b-form-group>
         <b-form-group label="Adviesnummer aanpassen" v-else>
           <b-input v-model="profiel.adviesnummer">
           </b-input>
         </b-form-group>
         <b-button  v-if="profiel.admin" @click="saveProfiel">Adviesnummer opslaan</b-button>
       </b-card>
    </b-container>
</template>
<script>
import { mapGetters } from 'vuex'
import { db, auth } from '../firebase'

export default {
  name: 'ProfielPagina',
  data () {
    return {
      profiel: null,
      units: [],
      elementen: null
    }
  },
  computed: {
    ...mapGetters({
      user: 'user'
    }),
    userId: function () {
      const user = auth.currentUser
      return user.uid
    }
  },
  mounted () {
    this.getProfiel()
  },
  methods: {
    saveProfiel () {
      db.collection('users').doc(this.userId).update(this.profiel)
    },
    WriteFetchedUnits () {
      this.units.forEach((u) => this.WriteUnit(u))
      // for (var u in this.units) this.WriteUnit(u)
    },
    WriteUnit (data) {
      console.log(data)
      var unitData = data
      var unitID = unitData.unit.RemoteId
      db.collection('units').doc(unitID).set(unitData.unit).then(() => {
        db.collection('units').doc(unitID).collection('prognoses').doc('HuidigePrognose').set(unitData.prognose)
        unitData.documenten.forEach(dc => {
          db.collection('units').doc(unitID).collection('documenten').doc(dc.FileMetadata.asset_id).set(dc)
        })
      })
    },
    getProfiel () {
      var vm = this
      console.log(this.userId)
      return db.collection('users').doc(this.userId).get().then(snapshot => {
        vm.profiel = snapshot.data()
      })
    }
  }
}
</script>
